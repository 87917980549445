import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';


interface Option {
  id: number;
  option_text: string;
  is_correct: boolean;
  reason_text: string;
}

interface Question {
  id: number;
  text: string;
  marks: number;
  options: Option[];
  image: string | null;
}

interface Test {
  id: number;
  name: string;
  description: string;
  time_limit: number;
  questions: Question[];
}

interface SelectTest {
  id: number;
  test: Test;
}


interface TestResponse {
  id: number;
  lecture_id: number;
  test: Test;
}

interface TestWithResponseId extends Test {
  responseId: number; // Adding responseId to the Test structure
}

const QuizPage: React.FC = () => {
  const { isAuthenticated, studentId, enrolledCourses, authIntriger, setAuthIntriger } = useAuth();

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const id = searchParams.get('id');
  const [tests, setTests] = useState<TestWithResponseId[]>([]);
  const [selectedTest, setSelectedTest] = useState<Test | null>(null);
  const [testResponse, setTestResponse] = useState<TestResponse | null>(null);

  // Quiz state
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);

  // Pagination and search
  const [currentPage, setCurrentPage] = useState(1);
  const testsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const [testResult, setTestResult] = useState('');
  const [remainingTime, setRemainingTime] = useState<number>(
    120 * 60 // Provide a fallback value
  );

  const token = localStorage.getItem('token');


  useEffect(() => {
    const fetchTests = async () => {
      if (id) {
        try {
          const endpoint =
            type === 'lecture'
              ? `http://localhost:8000/online-test/lecture-tests/`
              : type === 'chapter'
              ? `http://localhost:8000/online-test/chapter-tests/`
              : type === 'course'
              ? `http://localhost:8000/online-test/course-tests/`
              : '';

          const response = await axios.get(endpoint, {
            params: { [`${type}_id`]: id },
            headers: token ? { Authorization: `Token ${token}` } : undefined,
          });

          setTests(
            response.data.map((item: { id: number; test: Test }) => ({
              responseId: item.id, // Rename the outer `id` to `responseId`
              ...item.test, 
            })
          ));
        } catch (error) {
          console.error('Failed to fetch tests:', error);
        }
      }
    };
    fetchTests();
  }, [type, id]);
  

  const handleTestSelection = (test: Test) => {
    setSelectedTest(test);
    setRemainingTime((test?.time_limit || 120)*60)
    setUserAnswers({});
    setCurrentQuestionIndex(0);
    setSubmitted(false);
    setReviewMode(false);
  };

  const handleAnswer = (option: string) => {
    const currentQuestion = selectedTest?.questions[currentQuestionIndex];
    if (currentQuestion) {
      setUserAnswers((prev) => ({ ...prev, [currentQuestion.id]: option }));
    }
  };

  const nextQuestion = () => {
    if (selectedTest && currentQuestionIndex < selectedTest.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      submitTest();
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const API_BASE_URL = process.env.REACT_APP_API_URL;  // Replace with actual base URL


  // Create axios instance with token in headers
  const axiosInstance = axios.create({
      baseURL: API_BASE_URL,
      headers: {
          Authorization: `Token ${token}`  // Add token to Authorization header
      }
  });

  const saveTestResult = (result:string) => {
    if(selectedTest?.id){
      axiosInstance.post('/online-test/save-or-update-test-result/', {
        student_id: Number(studentId),
        test_id: Number(selectedTest.id),
        test_result: result,
      })
      .then(response => {
        console.log(response);
          // setTestId(response.data.id);
          // After test creation, create the LectureTest
      })
      .catch(error => console.error('Error submitting test result:', error));
    } else {
      // alert('Test not found');
    }
  };


  const submitTest = async () => {
    if (selectedTest) {
      try {
        // Use `responseId` for the API call
        const responseId = tests.find((test) => test.id === selectedTest.id)?.responseId;
  
        if (!responseId) {
          console.error('Response ID not found for the selected test.');
          return;
        }
  
        const responseEndpoint =
          type === 'lecture'
            ? `http://localhost:8000/online-test/lecture-test-response/${responseId}/`
            : type === 'chapter'
            ? `http://localhost:8000/online-test/chapter-test-response/${responseId}/`
            : type === 'course'
            ? `http://localhost:8000/online-test/course-test-response/${responseId}/`
            : '';
  
        const response = await axios.get(responseEndpoint, {
          headers: token ? { Authorization: `Token ${token}` } : undefined,
        });
        
        setTestResponse(response.data);
        setSubmitted(true);
      } catch (error) {
        console.error('Failed to fetch test response:', error);
      }
    }
  };

  
  
  useEffect(() => {
    if (!remainingTime) return;
  
    // Countdown logic
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);
  
    // Clear timer when the component unmounts
    return () => clearInterval(timer);
  }, [remainingTime]);
  
  // Format the time in mm:ss format
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  
  // Handle when the time runs out
  useEffect(() => {
    if (remainingTime === 0) {
      alert('Time is up! Submitting the test...');
      submitTest();
    }
  }, [remainingTime]);


  const calculateResult = () => {
    if (!testResponse) return { correct: 0, incorrect: 0 };
    let correct = 0;
    let incorrect = 0;

    testResponse.test.questions.forEach((question) => {
      const userAnswer = userAnswers[question.id];
      const correctOption = question.options.find((option) => option.is_correct);

      if (userAnswer === correctOption?.option_text) {
        correct++;
      } else {
        incorrect++;
      }
    });

    return { correct, incorrect };
  };

  useEffect(() => {
    if (submitted && testResponse) {
      const { correct, incorrect } = calculateResult();
      setTestResult(`${correct}/${correct+incorrect}`);
    }
  }, [submitted, testResponse]);


  useEffect(() => {
    saveTestResult(testResult);
  }, [testResult]);

  const filteredTests = tests.filter((test) =>
    test.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedTests = filteredTests.slice(
    (currentPage - 1) * testsPerPage,
    currentPage * testsPerPage
  );

  if (!selectedTest) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
        <h1 className="text-2xl font-bold mb-4">Select a Test</h1>
        <input
          type="text"
          placeholder="Search tests..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full max-w-md mb-4 p-2 border rounded"
        />
        {filteredTests.length > 0 ? (
          <>
            <ul className="w-full max-w-md">
              {paginatedTests.map((test) => (
                <li key={test.id} className="mb-2">
                  <button
                    onClick={() => handleTestSelection(test)}
                    className="w-full text-left py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    {test.name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Previous
              </button>
              <button
                onClick={() =>
                  setCurrentPage((prev) =>
                    prev * testsPerPage < filteredTests.length ? prev + 1 : prev
                  )
                }
                disabled={currentPage * testsPerPage >= filteredTests.length}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <p>No tests available for this {type}.</p>
        )}
      </div>
    );
  }

  

  if (submitted && testResponse) {
    const { correct, incorrect } = calculateResult();

    return (
      <div className="p-6 bg-green-100 rounded-lg">
        
        <h2 className="text-2xl font-bold mb-4">Quiz Results</h2>
        <p>Correct Answers: {correct}</p>
        <p>Incorrect Answers: {incorrect}</p>
        <h3 className="text-xl font-bold mt-4">Review Answers</h3>
        {testResponse.test.questions.map((question) => {
          const userAnswer = userAnswers[question.id];
          const correctOption = question.options.find((option) => option.is_correct);
          const selectedOption = question.options.find(
            (option) => option.option_text === userAnswer
          );

          return (
            <div key={question.id} className="mb-4">
              <p className="font-bold">{question.text}</p>
              <p>
                <strong>Your Answer:</strong>{' '}
                {selectedOption ? selectedOption.option_text : 'Not Answered'}
              </p>
              {!selectedOption?.is_correct && (
                <p>
                  <strong>Reason:</strong> {selectedOption?.reason_text || 'No reason provided.'}
                </p>
              )}
              <p>
                <strong>Correct Answer:</strong> {correctOption?.option_text}
              </p>
              <p>
                <strong>Explanation:</strong> {correctOption?.reason_text}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  const currentQuestion = selectedTest.questions[currentQuestionIndex];

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
      { isAuthenticated ? (
          <>
          { studentId ? (
            <div className="bg-white shadow-lg rounded-xl p-6 max-w-xl w-full">
            <div className='flex justify-between'>
              <h2 className="text-2xl font-bold mb-4">{selectedTest.name}</h2>
              {/* Timer Section */}
              <div className="bg-blue-500 text-white px-4 py-0 rounded shadow-md flex justify-between items-center space-x-6">
                {/* <h1 className="text-2xl font-bold">Quiz Timer</h1> */}
                <p className="text-xl font-semibold">{formatTime(remainingTime)}</p>
              </div>
            </div>
            <p className="text-lg mb-4">{currentQuestion.text}</p>
            {currentQuestion.image && (
              <img src={currentQuestion.image} alt="Question" className="mb-4 max-w-full h-auto" />
            )}
            <ul className="mb-6">
              {currentQuestion.options.map((option) => (
                <li key={option.id}>
                  <button
                    onClick={() => handleAnswer(option.option_text)}
                    className={`block w-full text-left py-2 px-4 mb-2 border rounded ${
                      userAnswers[currentQuestion.id] === option.option_text
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200'
                    }`}
                  >
                    {option.option_text}
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex justify-between">
              <button
                onClick={prevQuestion}
                disabled={currentQuestionIndex === 0}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Previous
              </button>
              <button
                onClick={nextQuestion}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {currentQuestionIndex === selectedTest.questions.length - 1
                  ? 'Submit'
                  : 'Next'}
              </button>
            </div>
          </div>
          ):(
            <div> 
              <p className='text-xl text-blue-500'>Please select student from<Link to={'/usersPage'} className='text-red-500 font-bold'>UserPage</Link> first!</p>
            </div>
          )}
          </>
        ):(
          <>
          <div> 
            <p className='text-xl text-blue-500'>Please <Link to={'/login'} className='text-red-500 font-bold border-rounded bg-blue-400'>Login</Link> first!</p>
          </div>
          </>
        )}
      
    </div>
  );
};

export default QuizPage;
