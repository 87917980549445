import SocialMediaLinks from '../components/SocialMediaLinks';
import { GenreData } from '../components/interfaces';
import React from 'react';
import { useState } from 'react';


const carrierData: GenreData = {
  genres: [
    {
      icon: '👨‍⚕️',
      title: 'Medical',
    },
    {
      icon: '👷‍♂️',
      title: 'Engineering',
    },
    {
      icon: '💉',
      title: 'Para-Medical',
    },
    {
      icon: '🛠️',
      title: 'ITI',
    },
    {
      icon: '👔',
      title: 'Government',
    },
    {
      icon: '🪖',
      title: 'Army',
    },
    {
      icon: '💻',
      title: 'Computer',
    },
    {
      icon: '👨‍🏫',
      title: 'Teachers',
    },
    {
      icon: '🏢',
      title: 'PVt Jobs',
    },
  ],
};

const More: React.FC = () => {
  const [user, setUser] = useState('Eemam')


  return (
    <div className="container mx-auto p-4 flex justify-center">
      <div className='w-full max-w-5xl flex flex-col items-center gap-4'>
        
      <div className="flex flex-col items-center bg-white p-4">
          <h1 className="text-3xl font-bold mb-4">Our Social Media Links</h1>
          <div className='text-2xl font-bold mb-4'>Follow Us</div>
          <SocialMediaLinks />
        </div>

        <div className="flex flex-col items-center bg-white p-4">
          <h1 className="text-3xl font-bold mb-4">Select Your Carreir Path</h1>
          <div className='text-2xl font-bold'>Carrer Options</div>
          <div className="grid grid-cols-3 md:grid-col-4 lg:grid-cols-6 gap-4 md:gap-6 lg:8">
            {carrierData.genres.map((genre) => (
              <div
                key={genre.title}
                className='rounded-xl shadow-lg lg:shadow-xl p-4 cursor-pointer  hover:bg-gray-100' >
                <div className="text-center text-2xl font-bold mb-2">
                  {genre.icon}
                </div>
                <div className="text-center">{genre.title}</div>
              </div>
            ))}
          </div>
        </div>
        <div className='hidden'>
        <div className='text-2xl font-bold pt-4'>Get your Goal path using Pathfinder</div>
        <div className='shadow-xl rounded-xl w-96 h-96 bg-white m-2 flex flex-col p-4'>
          <h1 className='text-4xl font-bold flex justify-start'>
            Hello,
          </h1>
          <h2 className='text-2xl font-bold flex justify-start'>
            I'm your PathFinder.
          </h2>
        </div>
      </div>
      </div>
    </div>
  );
};

export default More;
