// LecturePage.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getYouTubeVideoId } from '../lib/getYouTubeVideoId'


const JournalsIcon = () => <span>📓</span>;
const BookHalfIcon = () => <span>📖</span>;
const ChatLeftDotsIcon = () => <span>💬</span>;
const ClipboardCheckIcon = () => <span>✅</span>;
const LayoutWtfIcon = () => <span>🔗</span>;
const TestIcon = () => <span>📝</span>;

const YouTubeIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="100%" 
    fill="currentColor" 
    className="bi bi-youtube" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
    />
  </svg>
);

interface User {
  first_name: string;
  last_name: string;
}

interface Teacher {
  user: User;
}

interface Lecture {
  id: number;
  topic: string;
  description: string;
  is_unlocked: boolean;
  yt_link_ref: string;
  cnotes_ref: string | null;
  notes_ref: string | null;
  dpp_ref: string | null;
  is_locked: boolean;
  chapter: number;
  created_by: Teacher;
}

const LectureDisplay: React.FC<{ lecture: Lecture}> = ({ lecture}) => {
  
  const videoId = lecture.yt_link_ref ? getYouTubeVideoId(lecture.yt_link_ref) :'BDQEwufOUY0'; // Replace with dynamic video ID if needed
  const thumbnailUrl = lecture.yt_link_ref ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : '';
  // const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  
  const extractFileName = (filePath: string): string => {
    return filePath.replace('/media/', '').replace('.pdf', '');
  };

  const [url, setUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state

  useEffect(() => {
    if (videoId) {
      setUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&rel=0&modestbranding=1&showinfo=0&autohide=1&controls=1`);
    } else {
      setUrl("https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1&rel=0&modestbranding=1&showinfo=0&autohide=1&controls=1");
    }
  }, [videoId]);
  console.log(url);

  const handleFullscreen = () => {
    if (iframeContainerRef.current) {
      if (iframeContainerRef.current.requestFullscreen) {
        iframeContainerRef.current.requestFullscreen();
      } else if ((iframeContainerRef.current as any).webkitRequestFullscreen) {
        // For Safari
        (iframeContainerRef.current as any).webkitRequestFullscreen();
      } else if ((iframeContainerRef.current as any).msRequestFullscreen) {
        // For IE
        (iframeContainerRef.current as any).msRequestFullscreen();
      }
      setIsFullscreen(true);
    }
  };
  

  const handleExitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
      setIsFullscreen(false);
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
      setIsFullscreen(false);
    }
  };



  const startTime = url.includes('t=') ? parseInt(url.split('t=')[1]) : 0;
  

  return (
    <div className="bg-white rounded-xl shadow-md p-1 my-1">
      <div className="flex flex-col">
        <div>
          {/* <Link to={`/VideoContent/${videoId}`} >
            <div className="relative w-full">
              <img
                src={thumbnailUrl}
                alt={lecture.topic}
                className="rounded-xl w-full h-full md:w-60 md:h-40 object-cover"
              />
              <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold  flex justify-start items-center space-x-4"> */}
                {/* Lecture {lectureNum + 1}  */}

                {/* <div className=' text-red-600 w-6'>
                  <YouTubeIcon />
                </div>
              </div> */}
              
              {/* Transparent clickable overlay */}
              {/* <div className="absolute inset-0 w-full h-full cursor-pointer"></div>
            </div>
          </Link> */}
          <div
            ref={iframeContainerRef} 
            className="w-full h-0 relative "
            style={{ paddingBottom: '56.25%' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <iframe
              className="absolute top-0 left-0 w-full h-full rounded-t-xl"
              src={url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>

            {/* {isHovered && (
              <>
                <div className="absolute top-0 left-0 w-full h-24 flex items-center justify-center bg-black bg-opacity-50 text-white text-xl">
                  NAE COACHING
                </div>
                <div className="absolute bottom-0 left-0 w-full h-24 flex items-center justify-center bg-black bg-opacity-50 text-white text-xl">
                  NAE COACHING
                </div>
              
              </>
            )} */}
            {isFullscreen && (
              <button
                onClick={handleExitFullscreen}
                className="absolute bottom-2 right-4 bg-gray-900 text-[10px] text-white p-1 rounded-xl border-2 border-white shadow-lg hover:bg-gray-700 z-50"
              >
                Min
              </button>
            )}
            {!isFullscreen && (
              <button
                onClick={handleFullscreen}
                className="absolute bottom-2 right-4 bg-gray-900 text-[10px] text-white p-1 rounded-xl border-2 border-white shadow-lg hover:bg-gray-700 z-50"
              >
                Full
              </button>
            )}
            <>
              <div className="absolute top-0 right-0 w-full h-24 flex items-center justify-center bg-black bg-opacity-0 text-white text-xl">
                
              </div>
              <div className="absolute bottom-12 right-0 w-full h-full flex items-center justify-center bg-black bg-opacity-0 text-white text-xl">
                
              </div>
              <div className="absolute bottom-0 right-0 w-32 bg-black h-9 flex items-center justify-center bg-opacity-0 text-white text-xl">
                  
              </div>
            </>
          </div>
          {/* <div className="mt-4 text-center">
          <button
            onClick={handleFullscreen}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Fullscreen
          </button>
        </div> */}
          {/* <div className="relative w-full">
            <img
              src={thumbnailUrl}
              alt="lecture thumbnail"
              className="rounded-xl w-full md:w-60 h-48 md:h-40 object-cover"
            />
            <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold">
              Lecture {lectureNum + 1}
            </div>
          </div> */}
        </div>
        <div>
          {/* <div className="p-2 lg:pl-8">
            <p className="text-lg font-bold">{lecture.topic}</p>
            <p className="text-lg">{lecture.description}</p>
          </div> */}
          <div className="w-full px-4 lg:px-8 flex gap-2 md:gap-4 justify-between">
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-blue-600 w-8 md:w-10">
              <JournalsIcon />
              <p className="text-sm text-black">cNotes</p>
            </Link>
            <Link to={lecture.notes_ref? `/pdf/${extractFileName(lecture.notes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-black w-10 md:w-12">
              <BookHalfIcon />
              <p className="text-sm text-black">xNotes</p>
            </Link>
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-black w-8 md:w-10">
              <ChatLeftDotsIcon />
              <p className="text-sm text-black">Doubts</p>
            </Link>
            <Link to={lecture.dpp_ref? `/pdf/${extractFileName(lecture.dpp_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-purple-700 w-10 md:w-12">
              <ClipboardCheckIcon />
              <p className="text-sm text-black">DPP</p>
            </Link>
            <Link to={`/QuizPage/?type=lecture&id=${lecture.id}`} className="flex flex-col justify-start items-center text-2xl text-purple-700 w-10 md:w-12">
              <TestIcon />
              <p className="text-sm text-black">Test</p>
            </Link>
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-green-900 w-10 md:w-12">
              <LayoutWtfIcon />
              <p className="text-sm text-black">More</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const LecturePage: React.FC = () => {
  const location = useLocation();
  const lecture = location.state as Lecture;

  if (!lecture) {
    return <div>Lecture not found</div>;
  }

  return (
    <div className="container mx-auto p-1">
      <h1 className="text-2xl font-bold px-2">{lecture.topic}</h1>
      <p className="mb-2 px-2">{lecture.description}</p>
      <LectureDisplay key={lecture.id} lecture={lecture} />

      
      <a href={lecture.yt_link_ref} className="text-blue-500 hover:underline">
        Watch Lecture
      </a>
    </div>
  );
};

export default LecturePage;
