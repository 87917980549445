import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string | null;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

const PaymentPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Default values in case state is missing
  const state = location.state as { courses?: Course[]; totalPrice?: number };
  const courses = state.courses || [];
  const totalPrice = state.totalPrice ?? 0; // Use 0 if totalPrice is undefined or null

  const handlePayment = (e: React.FormEvent) => {
    e.preventDefault();

    // Simulate payment processing
    setTimeout(() => {
      alert('Payment successful!');
      // navigate('/confirmation');
    }, 1000);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Payment Page</h1>

      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
        {courses.length > 0 ? (
          courses.map((course) => (
            <div key={course.id} className="flex justify-between items-center mb-4">
              <img
                src={course.course_img || '/placeholder.png'}
                alt={course.course_name}
                className="w-16 h-16 object-cover rounded-md"
              />
              <p className="text-lg">{course.course_name}</p>
              <p className="text-lg">₹{parseFloat(course.price) - parseFloat(course.price)*parseFloat(course.discount_percentage)/100}</p>
            </div>
          ))
        ) : (
          <p>No courses selected.</p>
        )}
        <div className="border-t border-gray-300 pt-4">
          <p className="text-xl font-bold">Total: ₹{totalPrice.toFixed(2)}</p>
        </div>
      </div>

      <form onSubmit={handlePayment} className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Payment Details</h2>

        {/* Add your payment fields here */}

        <button
          type="submit"
          className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Complete Payment
        </button>
      </form>
    </div>
  );
};

export default PaymentPage;
