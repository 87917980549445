import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Courses';
import Progress from './pages/Progress';
import More from './pages/More';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Navigation from './components/Navigation'; // Ensure the path is correct
import Users from './pages/Users';
import Profile from './pages/Profile';
import QuizPage from './pages/ObjTest';
import YouTubeVideo from './pages/YouTubeVideo';
import PdfReader from './pages/PdfReader';
import CoursePage from './pages/CourseDetails';
import AboutUs from './pages/AboutUs';
import CollectiveCoursesPage from './pages/CollectiveCoursePage';
import CoursesPage from './pages/CoursesPage';
import PaymentPage from './pages/PaymentPage';
import NotFoundPage from './pages/NotFoundPage';
// import useDeviceUUID from './components/UUID';
import { getOrCreateDeviceUUID } from './components/DeviceActivity';
// import InstallModal from './components/InstallationModal';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer';
import CourseDetailPage from './pages/CoursePage';
import ChapterPage from './pages/ChapterPage';
import LecturePage from './pages/LecturePage';
import InstallButton from './components/InstallationButton';
import InstallButton2 from './components/InstallButton2';


const App: React.FC = () => {
  // const [deviceUUID, setDeviceUUID] = useState<string>('');
  // useDeviceUUID();
  
  useEffect(() => {
    getOrCreateDeviceUUID();
  }, []);
  
  return (
    <>
      <>
        <div className='w-full h-full flex flex-col bg-white p-1'>
          <Helmet>
            <title>N A E COACHING</title>
            <meta name="description" content="Bihar board online and offline coaching. Coaching in Thakurganj, Kishanganj (Bihar)." />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          </Helmet>
          <InstallButton />
          <div className='flex-1 overflow-y-auto pb-16 bg-white pt-24'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/progress" element={<Progress />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/more" element={<More />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/usersPage" element={<Users />} />
              <Route path="/courseDetailPage/:courseId" element={<CoursePage />} />
              <Route path="/QuizPage" element={<QuizPage />} />
              <Route path="/VideoContent/:videoId" element={<YouTubeVideo />} />
              <Route path="/pdf/:subPath?/:pdfLink?" element={<PdfReader defaultFileUrl="/NaeGetTheCourse.pdf" />} />              {/* <Route path="/pdf-reader" element={<PdfReader />} />             */}
              <Route path="/about-us" element={<AboutUs />} /> {/* AboutUs Route */}
              {/* <Route path="/pdf-reader" element={<PdfReader />} />             */}
              <Route path="/collectiveCoursesPage/:id" element={<CollectiveCoursesPage />} />
              <Route path="/CoursesPage/:className" element={<CoursesPage />} />
              <Route path="/payment-page" element={<PaymentPage />} />
              <Route path="/coursePage/:courseId" element={<CourseDetailPage />} />
              <Route path="/chapter/:chapterId" element={<ChapterPage />} />
              <Route path="/lecture/:lectureId" element={<LecturePage />} />


              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <InstallButton2 />
          <Footer />
          <Navigation />
        </div>
      </>
    </>
  );
}


export default App;
