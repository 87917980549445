import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string | null;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

export const CourseCard = ({ course }: { course: Course }) => {
  const navigate = useNavigate();
  const { isAuthenticated, studentId, studentClass, enrolledCourses } = useAuth();

  // Check if the current course is already enrolled
  const isEnrolled = enrolledCourses.includes(course.id);
  


  const handleViewDetails = () => {
    navigate(`/coursepage/${course.id}`);
  };  

  const discountedPrice = (
    parseFloat(course.price) -
    (parseFloat(course.price) * parseFloat(course.discount_percentage)) / 100
  ).toFixed(2);


  return (
    <div className="min-w-60 max-w-80 h-full bg-white rounded-2xl shadow-md p-3 flex flex-col my-1">
      <div className="flex items-center w-full">
        <img
          src={course.course_img || '/placeholder.png'} // Placeholder if image is null
          alt={course.course_name}
          className="w-full object-cover rounded-2xl mb-2"
        />
      </div>

      <div className="p-3 flex flex-col flex-grow">
        <h3 className="text-xl font-semibold mb-2">{course.course_name}</h3>
        <p className="text-lg text-gray-600 mb-2">{course.description}</p>
        {/* <p className="text-sm text-gray-400 mb-2">
          Teacher: {course.teacher.user.first_name} {course.teacher.user.last_name}
        </p>
        <p className="text-sm text-gray-400 mb-2">Mode: {course.mode}</p>
        <p className="text-sm text-gray-400 mb-2">Tags: {course.tags.map(tag => tag.name).join(', ')}</p> */}
        {/* <p className="text-lg font-semibold mb-2">Price: ${course.price}</p>
        <p className="text-sm text-gray-600 mb-2">Discount: {course.discount_percentage}%</p> */}
        
        {isEnrolled ? (
        <Link to={`/coursePage/${course.id}`} className="mt-auto bg-green-500 font-semibold text-white px-4 py-2 rounded-xl hover:bg-green-600 transition duration-300 text-center">
         Go to Course
        </Link>
        ) : (
          <>
          <div className="flex items-center justify-between">
          <span className="text-lg font-bold text-gray-800">
            ₹{discountedPrice}
          </span>
          <span className="text-md font-bold text-gray-500 line-through">
            ₹{course.price}
          </span>
        </div>
        <span className="text-sm font-medium text-red-600">
            Save {course.discount_percentage}%!
          </span>

          <button
          onClick={handleViewDetails}
          className="mt-auto bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 transition duration-300"
        >
          View Details
        </button>
          </>
        
        )}
        
      </div>
    </div>
  );
};


const CourseList = ({ courses }: { courses: Course[] }) => {
  
  return (
    <div className="container mx-auto px-2 py-4">
      <div className="flex overflow-x-auto space-x-6 no-scrollbar py-3">
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </div>
      <div className="mt-8">
        <Link to={'/courses'} >
        <button className="bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
          Show All
        </button>
        </Link>
      </div>
    </div>
  );
};


export default CourseList;
