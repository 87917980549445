import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Lecture {
  id: number;
  topic: string;
  description: string;
  is_unlocked: boolean;
  is_completed: boolean;
}

interface Chapter {
  id: number;
  title: string;
  is_completed: boolean;
  yt_link_ref: string | null;
  course: number;
  lectures: Lecture[];
}

interface Course {
  id: number;
  class_for: { name: string }[];
  tags: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  course_name: string;
  description: string;
  course_img: string;
  is_completed: boolean;
  mode: string;
  price: string;
  discount_percentage: string;
  chapters: Chapter[];
}

const ContinueCourse: React.FC<{ courses: Course[] }> = ({ courses }) => {
  const [expandedCourses, setExpandedCourses] = useState<{ [key: string]: boolean }>({});

  const toggleExpand = (title: string) => {
    setExpandedCourses((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl lg:text-3xl font-bold mb-4">Continue Course</h2>
      
      <div className="w-full flex flex-wrap w-max-6xl justify-center gap-8 lg:gap-12">
        {courses.map((course) => (
          <div
            key={course.course_name}
            className="w-full md:w-1/2 lg:w-1/3 bg-white rounded-xl lg:rounded-2xl shadow-md p-4 "
          >
            <div className='flex'>
            <div>
            <img
              src={course.course_img}
              className="w-auto h-20 md:w-24 md:h-24 rounded-xl p-1 mr-2 object-cover"
            />
            </div>
            <div className='p-2'>
            <h3 className="text-xl font-bold mb-2">{course.course_name}</h3>
            {/* <p className="text-gray-700 mb-4 font-bold">Ongoing: {course.curret_lesson}</p> */}
            
            {/* <div className="flex w-full items-center bg-gray-200 rounded-full mb-2" >
              <div
                className="bg-green-400 rounded-full h-4 w-full text-[10px] text-center"
                style={{ width: `${course.tprogress}%` }}
              >
                <span className="text-end text-gray-700 text-[12px]">{course.tprogress}%</span>
              </div>
            
            </div> */}

            {/* <div className="flex items-center bg-gray-200 rounded-full" >
              <div
                className="bg-yellow-400 rounded-full h-4 w-full text-[10px] text-center"
                style={{ width: `${course.sprogress}%` }}
              >
                <span className="text-end text-gray-700 text-[12px]">{course.sprogress}%</span>
              </div>
            
            </div> */}
            </div>
            </div>
            {expandedCourses[course.course_name] && (
              <div className="mt-4">
                <CourseSyllabus course={course} />
              </div>
            )}
            <div className='flex justify-between'>
            <button
              className="mt-4 px-4 py-1 bg-blue-500 rounded-full text-white"
              onClick={() => toggleExpand(course.course_name)}
            >
              {expandedCourses[course.course_name] ? 'Close' : 'Syllabus'}
            </button>
            <Link to={`/coursePage/${course.id}`}>
              <button
                className=" mt-4 px-4 py-1 bg-green-900 rounded-full text-white">
              Go to Course
              </button>
            </Link>
            </div>
            
          </div>

        ))}
      </div>

    </div>
  );
};

const CourseSyllabus: React.FC<{ course: Course }> = ({ course }) => {
  return (
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="font-bold mb-4">Syllabus</h1>
      <div className="space-y-4">
        {course.chapters.map((chapter) => (
          <div key={chapter.id} className="p-4 bg-gray-100 rounded-md">
            <div className="flex items-center">
              <h2 className="text-black font-semibold">{chapter.title}</h2>
              {chapter.is_completed && (
                <span className="text-green-500 ml-2">&#10003;</span> // Unicode checkmark
              )}
            </div>
            <ul className="list-disc ml-6 mt-2">
              {chapter.lectures.map((lecture) => (
                <li key={lecture.id} className="text-gray-700 flex items-center justify-between">
                  <h2 className='text-gray-800'> {lecture.topic}</h2>
                  {lecture.is_completed && (
                    <div className='flex bg-blue-300 px-2 rounded-xl text-sm self-center'>
                      <span className="text-green-700 ml-2">completed</span> 
                    <span className="text-green-700 ml-2">&#10003;</span> 
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ContinueCourse;
