import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getCourseDetails = async (courseId: number, studentId?: number) => {
    const url = studentId 
        ? `${API_BASE_URL}/courses/course-details/${courseId}/${studentId}/`
        : `${API_BASE_URL}/courses/course-details/${courseId}/`;
    console.log(url);
    
    const token = localStorage.getItem('token');

    try {
        const headers: { Authorization?: string } = {};

        // Add Authorization header only if the token exists
        if (token) {
        headers.Authorization = `Token ${token}`;
        }

        const response = await axios.get(url, { headers });
        return response.data;
    } catch (error) {
        console.error('Error fetching course details', error);
        throw error;
    }
};
