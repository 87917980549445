import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getOrCreateDeviceUUID } from '../components/DeviceActivity';

interface OtpError {
  error: string;
  [key: string]: any; // Any other properties that might be present
}

const Signup: React.FC = () => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if(isAuthenticated){
      navigate('/');
    }
  
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    student_class: '', 
    subjects: '',
    qualifications: '',
    experience: '',
    password: '',
    confirmPassword: '',
    role: 'student'  // Default role
  });
  const [deviceId, setDeviceId] = useState<string>(Cookies.get('device_uuid') || '');
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [varified, setVarified] = useState(false);
  const { signup, login, sendOtp, verifyOtp } = useAuth();
  const navigate = useNavigate();
  const [runCount, setRunCount] = useState(0); // Track the number of times `useEffect` has run



  useEffect(() => {
    if (deviceId === '') {
      if (runCount < 1) {
        getOrCreateDeviceUUID();
        setDeviceId(Cookies.get('device_uuid') || '')
      }
      if (runCount < 2) { // Only run if `runCount` is less than 2
        setDeviceId(Cookies.get('device_uuid') || '')
        setRunCount(runCount + 1); // Increment the count after each run
      }
    }
  }, [runCount]);
  
  
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };
  

  // Validation function
  const validateForm = () => {
    // Destructure necessary fields from formData
    const { firstName, email, student_class, password, confirmPassword, phoneNumber } = formData;

    // Check required fields
    if (!firstName.trim() && isValidEmail(email) && !student_class.trim() && !password.trim() && isValidPhoneNumber(phoneNumber)) {
        setError("Fill all required fields correctly.");
        return false;
    }

    // Check if confirmPassword matches password
    if (password !== confirmPassword) {
        setError("Passwords do not match.");
        return false;
    }

    // If all validations pass
    return true;
  };



    
  const handleSendOtp = async () => {
    try {
      if (deviceId !== '') {
        const responseData = await sendOtp(formData.phoneNumber, deviceId);
        setError(responseData.message);
        setOtpSent(true);
        setStep(6);
      }
    } catch (error) {
      const err = error as Error;
      setError(err.message || 'Failed to send OTP. Try again.');
    }
  };
  
  const handleVerifyOtp = async () => {
    try {
      const verification = await verifyOtp(formData.phoneNumber, otp);
      console.log(verification);
      
      setVarified(verification.verified)
      console.log(verification.verified);
      
      if (verification.verified) {
        setError(verification.message);
        console.log('handle submit')
        handleSubmit();
        console.log('handled submit');
        
        // setStep(7);
      } else {
        // Handle the case when verification fails, e.g., show an error message
        setError('OTP did not get verified, please enter Correct OTP.');
      }// Move to next step after OTP verification
    } catch (error) {
      const err = error as OtpError;
      setError(err.error || 'OTP verification failed. Try again.');
    }
  };
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleNext = async () => {
    if (step === 1 && formData.firstName && formData.lastName) {
      setStep(2);
    } else if (step === 2 && formData.email) {
      if (!isValidEmail(formData.email)) {
        setError('Invalid email format.');
        return;
      }
      setError('');
      setStep(3);
    } else if (step === 3 && formData.student_class) {
      setStep(4);
    } else if (step === 4) {
      if (formData.password.length < 6) {
        setError('Password must be at least 6 characters long.');
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      setError('');
      setStep(5);
      // handleSubmit();
    } else if (step === 5 && formData.phoneNumber) {
      if (!isValidPhoneNumber(formData.phoneNumber)) {
        setError('Invalid phone number. It must be 10 digits and start with 6-9.');
        return;
      }
      setError('');
      handleSendOtp(); //new
      // setStep(4);
    } else {
      setError('Please fill in all fields correctly.');
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = async () => {
    try {
      await signup(formData);
      await login(formData.phoneNumber, formData.password);
      navigate('/usersPage');
    } catch (err) {
      setError('Signup failed. Please try again.');
    }
  };

  

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-md">
        <h2 className="text-2xl mb-4 font-bold">Signup</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {step === 1 && (
          <>
            <input
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
            <input
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </>
        )}

        {step === 2 && (
          <input
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
          />
        )}

        {/* {step === 3 && (
          <input
            name="phoneNumber"
            placeholder="Phone Number"
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
          />
        )}

        {step === 4 && otpSent && (
          <>
            <input type="text" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} />
            <button onClick={handleVerifyOtp} className='bg-black p-1 text-white m-1'>Verify OTP</button>
          </>
        )} */}

        {step === 3 && (
          <>
          <p className='text-gray-500 font-medium text-center'>Choose your Class</p>
          <select
            name="student_class"
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            defaultValue="CLASS 6"
            value={formData.student_class}
          >
            <option value="CLASS 6">CLASS 6</option>
            <option value="CLASS 7">CLASS 7</option>
            <option value="CLASS 8">CLASS 8</option>
            <option value="CLASS 9">CLASS 9</option>
            <option value="CLASS 10">CLASS 10</option>
            <option value="SCIENCE 11">SCIENCE 11</option>
            <option value="SCIENCE 12">SCIENCE 12</option>
            <option value="ARTS 11">ARTS 11</option>
            <option value="ARTS 12">ARTS 12</option>
            {/* <option value="DELED">DELED</option>
            <option value="CTET 1">CTET 1</option> */}
          </select>
          </>
        )}

        {step === 4 && (
          <>
            <input
              name="password"
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
            <input
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </>
        )}
        {step === 5 && (
          <input
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
          />
        )}

        {step === 6 && otpSent && (
          <>
            <input type="text" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} />
          </>
        )}

        <div className="flex justify-between mt-4">
          {step > 1 && (
            <button
              type="button"
              onClick={handleBack}
              className="px-4 py-2 bg-gray-500 text-white rounded-xl hover:bg-gray-600"
            >
              Back
            </button>
          )}
          {step < 6 && (
          <button
            type="button"
            onClick={handleNext}
            className="px-4 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600"
          >
            {step === 5 ? 'get OTP' : 'Next'}
          </button>
          )} 
          {step === 6 && (
          <button
            type="button"
            onClick={handleVerifyOtp}
            className="px-4 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600"
          >
            Verify OTP
          </button>
          )} 
          
        </div>
      </div>
    </div>
  );
};

export default Signup;
