"use client";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { cn } from "../lib/utils";
import { getYouTubeVideoId } from "../lib/getYouTubeVideoId";
import { Link } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

interface Notification {
  id: number;
  name: string;
  description: string;
  icon: string;
  color: string;
  youtube_link?: string | null;
  pdf?: string | null;
  image_url?: string | null;
  link?: string | null;
  expiration_date?: string | null;
}

const Notification = ({
  name,
  description,
  icon,
  color,
  youtube_link,
  pdf,
  image_url,
  link,
}: Notification) => {
  const videoId = youtube_link? getYouTubeVideoId(youtube_link) :null; 
  return (
    <figure
      className={cn(
        "relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-2xl p-4",
        "transition-all duration-200 ease-in-out hover:scale-[103%]",
        "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
        "dark:bg-gray-800 dark:shadow-md"
      )}
    >
      <div className="flex flex-row items-center gap-3">
        <div className="flex items-start h-full">
        <div
          className="flex size-10 items-center justify-center rounded-xl"
          style={{
            backgroundColor: color,
          }}
        >
          <span className="text-lg">{icon}</span>
        </div>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center text-lg font-medium dark:text-white">
            <span>{name}</span>
          </figcaption>
          <p className="text-sm font-normal dark:text-gray-300">{description}</p>
        </div>
      </div>
      <div className="mt-4 space-y-2">
        {youtube_link && (
          <Link  to={`/VideoContent/${videoId}`} className="text-white hover:underline bg-red-500 rounded-xl p-1">
            Watch Video
          </Link>
          
        )}
        {pdf && (
          <a
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            View PDF
          </a>
        )}
        {image_url && (
          <img
            src={image_url}
            alt="Notification"
            className="w-full h-auto rounded-lg"
          />
        )}
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Learn More
          </a>
        )}
      </div>
    </figure>
  );
};

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${API_URL}/users/notifications/active/`);
        // console.log(response);
        
        setNotifications(response.data.reverse());
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);


  if (notifications.length === 0) {
    return <div></div>;
  }
  

  return (
    <div className="p-4 bg-green-200 rounded-3xl">
      <div className="flex justify-between">
      <h1 className="text-2xl font-bold text-purple-950 dark:text-white mb-4">
        Notifications
      </h1>
      <div className="w-8 h-8 rounded-xl bg-purple-500 text-center flex justify-center items-center text-white font-bold text-lg">{notifications.length}</div>
      </div>
      {loading ? (
        <div>Loading notifications...</div>
      ) : (
        <div className=" max-h-60 overflow-y-auto overflow-x-hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 p-1 no-scrollbar">
          {notifications.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationsPage;
