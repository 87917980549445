import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL; // Adjust to your backend URL

export const getOrCreateDeviceUUID = async (): Promise<string> => {
  let deviceUUID = Cookies.get('device_uuid');
  // console.log('device uuid from cookies: ', deviceUUID);
  

  // Request device UUID from the backend if not found in cookies
  if (!deviceUUID) {
    const response = await axios.post(`${API_URL}/users/device/`);

    deviceUUID = response.data.device_uuid as string; // Assert that response is a string
    if (deviceUUID) {
      Cookies.set('device_uuid', deviceUUID, { expires: 7 * 365 });
      localStorage.setItem('device_uuid', deviceUUID);
    } else {
      throw new Error('Failed to retrieve device UUID');
    }
  }

  return deviceUUID;
};
