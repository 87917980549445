import React, { useState, useRef, useEffect } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { useParams } from 'react-router-dom';

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${require('pdfjs-dist/package.json').version}/build/pdf.worker.min.js`;

interface PdfReaderProps {
  defaultFileUrl: string;
}

const PdfReader: React.FC<PdfReaderProps> = ({ defaultFileUrl }) => {
  const { subPath, pdfLink } = useParams<{subPath?: string; pdfLink?: string }>();
  const API_URL = process.env.REACT_APP_API_URL || '';  // Ensure API_URL is correctly set
  const fileUrl = pdfLink ? `${API_URL}/media/${subPath}/${pdfLink}.pdf` : defaultFileUrl;
  
  const [pdfDoc, setPdfDoc] = useState<any>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState<{ src: string; height: number }[]>([]);
  const [containerHeight, setContainerHeight] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch the PDF file.');
        }
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const pdf = await getDocument({ data: arrayBuffer }).promise;
        setPdfDoc(pdf);
        setTotalPages(pdf.numPages);

        const pageImages: { src: string; height: number }[] = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          if (!context) return;

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport,
          };

          await page.render(renderContext).promise;
          const imgSrc = canvas.toDataURL();
          pageImages.push({ src: imgSrc, height: viewport.height });
        }
        setPages(pageImages);
        setContainerHeight(pageImages[0]?.height || 0);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    fetchPdf();
  }, []);



  return (
    <div className="pdf-reader p-4 mx-auto max-w-4xl border border-gray-300 rounded-lg shadow-lg bg-white" ref={containerRef}>
      <div className="flex justify-between items-center mb-4 border-b border-gray-200 pb-2">
        <div className="text-gray-700">
          {totalPages} pages
        </div>
        <div className="space-x-2">
          <p>{pdfLink}</p>
        </div>
      </div>
      <div className="relative overflow-y-auto h-screen no-scrollbar">
        <div
          className={`absolute transition-transform duration-500 ease-in-out bg-black`}
        >
          {pages.map((page, index) => (
            <div key={index} className="w-full h-full my-2">
              <img
                src={page.src}
                alt={`Page ${index + 1}`}
                className="w-full h-auto"
                style={{ maxHeight: '100%' }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PdfReader;
