import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CourseCard } from '../components/CourseListHome';
import { CollectiveCourse } from '../components/interfaces';
import { useAuth } from '../context/AuthContext';
import CollectiveCourseCard from '../components/CollectiveCourseCard';
import { useParams } from 'react-router-dom';



interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string | null;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

interface CoursesPageParams {
  className: string;
}


const CoursesPage: React.FC = () => {
  const { className } = useParams<Record<keyof CoursesPageParams, string>>(); // Updated type here  const [isLoading, setIsLoading] = useState(true);
  const [classCourses, setClassCourses] = useState<Course[]>([]);
  const [ClassCollectiveCourses, setClassCollectiveCourses] = useState<CollectiveCourse[]>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  

  useEffect(() => {

    const fetchClassCourses = async () => {
      const cachedClassCourses = localStorage.getItem(`Class${className}Courses`);
      const classCoursesExpiry = localStorage.getItem(`Class${className}CoursesExpiry`);
      const now = new Date();
  
      if (cachedClassCourses && classCoursesExpiry && now < new Date(classCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setClassCourses(JSON.parse(cachedClassCourses));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/courses/by-class/${className}`);
          console.log(response);
          
          setClassCourses(response.data);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`Class${className}Courses`, JSON.stringify(response.data));
          localStorage.setItem(`Class${className}CoursesExpiry`, new Date(now.getTime() + 1 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };
    
    const fetchClassCollectiveCourses = async () => {
      const cachedClassCollectiveCourses = localStorage.getItem(`Class${className}CollectiveCourses`);
      const classCollectiveCoursesExpiry = localStorage.getItem(`Class${className}CollectiveCoursesExpiry`);
      const now = new Date();
  
      if (cachedClassCollectiveCourses && classCollectiveCoursesExpiry && now < new Date(classCollectiveCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setClassCollectiveCourses(JSON.parse(cachedClassCollectiveCourses));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/collective-courses/by-class/${className}`);
          console.log(response);

          setClassCollectiveCourses(response.data);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`Class${className}CollectiveCourses`, JSON.stringify(response.data));
          localStorage.setItem(`Class${className}CollectiveCoursesExpiry`, new Date(now.getTime() + 10 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching Collectivecourses:', error);
        }
      }
    };
    
  

    
    fetchClassCourses();
    fetchClassCollectiveCourses();
  }, [API_URL]);

  return (
    <div className="container mx-auto p-4">

        <div>
        {classCourses.length != 0 ? (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Available courses</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {classCourses.map(course => (
                <div className='w-full flex justify-center items-center'>
                <CourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
        </div>
        ) : (<></>)}

        {ClassCollectiveCourses.length != 0 ? (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl mb-3 font-bold text-gray-900">Collective courses </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {ClassCollectiveCourses.map(course => (
                <div className='w-full flex justify-center items-center'>
                  <CollectiveCourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
        </div>
        ) : (<></>)}
        </div>

    </div>
  );
};

export default CoursesPage;
