// ChapterPage.tsx
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getYouTubeVideoId } from '../lib/getYouTubeVideoId'


interface User {
  first_name: string;
  last_name: string;
}

interface Teacher {
  user: User;
}

interface Lecture {
  id: number;
  topic: string;
  description: string;
  is_unlocked: boolean;
  yt_link_ref: string;
  cnotes_ref: string | null;
  notes_ref: string | null;
  dpp_ref: string | null;
  is_locked: boolean;
  chapter: number;
  created_by: Teacher;
}

interface Chapter {
  id: number;
  title: string;
  yt_link_ref: string | null;
  course: number;
  lectures: Lecture[];
}

const LectureDisplayImage: React.FC<{ lecture: Lecture, chapImg: string}> = ({ lecture, chapImg}) => {
  
  const videoId = lecture.yt_link_ref ? getYouTubeVideoId(lecture.yt_link_ref) :'BDQEwufOUY0'; // Replace with dynamic video ID if needed
  const thumbnailUrl = lecture.yt_link_ref ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : null;
  console.log(thumbnailUrl);
  
  return (
    <div className="bg-white rounded-xl shadow-md mr-4 w-full h-full max-w-32 max-h-30 ">
      {thumbnailUrl ? (
        <img
        src={thumbnailUrl}
        alt={lecture.topic}
        className="rounded-xl object-cover"
      />
      ) : (<></>)}
        
    </div>
  );
};

const ChapterPage: React.FC = () => {
  const location = useLocation();
  const chapter = location.state as Chapter;

  if (!chapter) {
    return <div>Chapter not found</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl md:text-2xl font-bold mb-4 text-center">{chapter.title}</h1>
      <div className="space-y-4">
        {chapter.lectures.length > 0 ? (
          <>
          {chapter.lectures.map((lecture, index) => (
            <div key={lecture.id} className="border p-4 rounded shadow hover:shadow-lg">
              <Link to={`/lecture/${lecture.id}`} state={lecture}  className='flex flex-row'>
                <div>
                  <LectureDisplayImage lecture={lecture} chapImg={chapter.yt_link_ref? chapter.yt_link_ref : ''}/>
                </div>
                <div>
                <p className='font-bold'> Lecture : {index + 1 }</p>
                <h2 className="text-lg font-semibold">{lecture.topic}</h2>
                <p>{lecture.description}</p>
                {/* <div
                  className="text-blue-500 hover:underline"
                >
                  View Lecture
                </div> */}
              </div>
            </Link>
            </div>
          ))}
          </>
        ) : (
          <div className='text-center'>No Lecture available, lectures will available very soon. </div>
        )}
        
      </div>
    </div>
  );
};

export default ChapterPage;
