import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CourseCard } from '../components/CourseListHome';
import { useAuth } from '../context/AuthContext';

interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string | null;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

interface CollectiveCourse {
  id: number;
  title: string;
  description: string;
  course_img: string | null;
  collective_price: string;
  discount_percentage: string;
  courses: number[];
}

const CollectiveCoursesPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [collectiveCourse, setCollectiveCourse] = useState<CollectiveCourse | null>(null);
  const [courses, setCourses] = useState<Course[]>([]);
  const { isAuthenticated, studentId, studentClass, enrolledCourses } = useAuth();
  const [collectivePrice, setCollectivePrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [discountedPercentage, setDiscountedPercentage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory


  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCollectiveCourse = async () => {
      try {
        const response = await axios.get<CollectiveCourse>(`${API_URL}/courses/collective-courses/${id}/`);
        setCollectiveCourse(response.data);

        const courseResponses = await Promise.all(
          response.data.courses.map((courseId) =>
            axios.get<Course>(`${API_URL}/courses/courses/${courseId}/`)
          )
        );
        setCourses(courseResponses.map(res => res.data));

        let totalCollectivePrice = 0;
        let totalDiscountedPrice = 0;

        courseResponses.forEach((res) => {
          const course = res.data;
          const coursePrice = parseFloat(course.price);
          const discount = parseFloat(course.discount_percentage);

          totalCollectivePrice += coursePrice;
          totalDiscountedPrice += coursePrice - (coursePrice * discount) / 100;
        });

        setCollectivePrice(totalCollectivePrice);
        setDiscountedPrice(totalDiscountedPrice);
        setDiscountedPercentage(((totalCollectivePrice - totalDiscountedPrice) / totalCollectivePrice) * 100);

      } catch (error) {
        console.error('Error fetching collective course or courses:', error);
      }
    };

    fetchCollectiveCourse();
  }, [id]);

  const handleTakeAllCourses = () => {
    setShowModal(true);
  };

  const handleProceed = () => {
    setShowModal(false);
    if (discountedPrice === 0) {
      alert('You have successfully enrolled in all courses!');
    } else {
      navigate('/payment-page', { state: { courses, totalPrice: discountedPrice } });
    }
  };

  if (!collectiveCourse) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <img
          src={collectiveCourse.course_img || '/placeholder.png'}
          alt={collectiveCourse.title}
          className="w-full h-64 object-cover rounded-md mb-4"
        />
        <h1 className="text-3xl font-bold mb-4">{collectiveCourse.title}</h1>
        <p className="text-lg text-gray-700 mb-4">{collectiveCourse.description}</p>
        <div className='flex justify-between w-96'>
          <p className="text-m font-semibold text-gray-900">
            ₹<span className='line-through'>{collectivePrice.toFixed(2)}</span>
          </p>
          <p className="text-xl font-semibold text-gray-900">
            Pay ₹{discountedPrice.toFixed(2)}
          </p>
        </div>
        <p className="text-xl text-red-500 font-bold mb-6">
          Save {discountedPercentage.toFixed(2)}%
        </p>
        <button
          onClick={handleTakeAllCourses}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg"
        >
          Take All Courses
        </button>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Courses Included</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-md w-11/12 md:w-2/3 lg:w-1/2">
            <h2 className="text-2xl font-bold mb-4">Your Bill</h2>
            <div className="space-y-4">
              {courses.map((course) => (
                <div key={course.id} className="flex justify-between items-center">
                  <img
                    src={course.course_img || '/placeholder.png'}
                    alt={course.course_name}
                    className="w-16 h-16 object-cover rounded-md"
                  />
                  <div>
                    <p className="text-lg font-semibold">{course.course_name}</p>
                    <p className="text-gray-500">
                      ₹{parseFloat(course.price).toFixed(2)} &rarr; ₹{(parseFloat(course.price) - (parseFloat(course.price) * parseFloat(course.discount_percentage)) / 100).toFixed(2)}
                    </p>
                  </div>
                </div>
              ))}
              <div className="border-t border-gray-300 pt-4">
                <p className="text-xl font-bold">
                  Total: ₹{discountedPrice.toFixed(2)}
                </p>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white py-2 px-4 rounded-lg mr-4"
              >
                Cancel
              </button>
              <button
                onClick={handleProceed}
                className="bg-green-500 text-white py-2 px-4 rounded-lg"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectiveCoursesPage;
