// src/components/InstallButton.tsx
import React, { useEffect, useState } from 'react';
import InstallModal from './InstallationModal';

const InstallButton: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true); 
      // console.log('isInstallable handle before install: ', isInstallable);
      // Show the install button when the app is installable
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsInstallable(false);  // Hide the install button after the prompt is handled
      });
    }
  };

  if (!isInstallable) {
    // console.log('isInstallable : ', isInstallable);
    
    return null;  // Hide the button if the app is not installable
  }

  return (
    <div>
      {/* Install Button */}
      <InstallModal onInstallClick={handleInstallClick} />
    </div>
  );
};

export default InstallButton;
