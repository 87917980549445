import React from "react";
import { 
  Youtube, 
  Instagram, 
  Facebook, 
  Twitter 
} from "lucide-react";

const SocialMediaLinks: React.FC = () => {
  return (
    <div className="flex space-x-4 justify-center md:justify-start">
      {/* YouTube */}
      <a
        href="https://www.youtube.com/channel/UCKuEqNavw6mt5P7zvO5N1bw"
        target="_blank"
        rel="noopener noreferrer"
        className="text-red-600 hover:text-red-800 transition-colors group"
        aria-label="YouTube"
      >
        <Youtube 
          className="group-hover:scale-110 transition-transform" 
          size={32} 
          strokeWidth={1.5} 
        />
      </a>

      {/* Instagram */}
      <a
        href="https://www.instagram.com/naecoachingthakurganj"
        target="_blank"
        rel="noopener noreferrer"
        className="text-pink-600 hover:text-pink-800 transition-colors group"
        aria-label="Instagram"
      >
        <Instagram 
          className="group-hover:scale-110 transition-transform" 
          size={32} 
          strokeWidth={1.5} 
        />
      </a>

      {/* Facebook */}
      <a
        href="https://www.facebook.com/NationalAcademicEnvironment?mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800 transition-colors group"
        aria-label="Facebook"
      >
        <Facebook 
          className="group-hover:scale-110 transition-transform" 
          size={32} 
          strokeWidth={1.5} 
        />
      </a>

      {/* Twitter */}
      <a
        href="https://twitter.com/YOUR_TWITTER_HANDLE"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-400 hover:text-blue-600 transition-colors group"
        aria-label="Twitter"
      >
        <Twitter 
          className="group-hover:scale-110 transition-transform" 
          size={32} 
          strokeWidth={1.5} 
        />
      </a>
    </div>
  );
};

export default SocialMediaLinks;