// Footer.tsx
import React from "react";
import { Link } from "react-router-dom";
import SocialMediaLinks from "./SocialMediaLinks";
import CommentList from "./CommentList";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-8 px-4">
      <CommentList />
      <div className="container mx-auto text-center md:text-left space-y-6">
        {/* Logo and Title */}
        <div className="flex flex-col items-center md:items-start mb-6 ">
          <img src="/logo192.png" alt="Logo" className="w-24 h-24 mb-2 rounded-full" />
          <span className="font-bold flex flex-col text-white text-center md:text-left">
            <span className="text-4xl font-extrabold mt-3">N A E</span>
            <span className="text-md">COACHING</span>
          </span>
        </div>

        <SocialMediaLinks />

        {/* Navigation Links */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-sm md:text-base mb-6">
          <Link to="/" className="hover:underline">Home</Link>
          <Link to="/courses" className="hover:underline">Courses</Link>
          <Link to="/progress" className="hover:underline">Progress</Link>
          <Link to="/profile" className="hover:underline">Profile</Link>
          <Link to="/more" className="hover:underline">More</Link>
          <Link to="/login" className="hover:underline">Login</Link>
          <Link to="/signup" className="hover:underline">Signup</Link>
          <Link to="/usersPage" className="hover:underline">Users</Link>
          <Link to="/about-us" className="hover:underline">About Us</Link>
          <Link to="/CoursesPage/SCIENCE 11" className="hover:underline">Science 11</Link>
          <Link to="/CoursesPage/SCIENCE 12" className="hover:underline">Science 12</Link>
          <Link to="/CoursesPage/ARTS 11" className="hover:underline">Arts 11</Link>
          <Link to="/CoursesPage/ARTS 12" className="hover:underline">Arts 12</Link>
          <Link to="/CoursesPage/CLASS 10" className="hover:underline">Class 10</Link>
          <Link to="/CoursesPage/NEET 11" className="hover:underline">NEET 11</Link>
          <Link to="/CoursesPage/NEET 12" className="hover:underline">NEET 12</Link>
        </div>



        {/* Location and Contact Information */}
        <div className="text-sm md:text-base mb-4 space-y-1 text-center md:text-left">
          <p>Near Railway Gate, Thakurganj, Bihar</p>
          <p>Contact: +91 7979061569</p>
        </div>

        {/* Tagline */}
        <p className="text-lg italic text-gray-400 mb-4 text-center md:text-left">
          We don't promise, We've Plans.
        </p>

        {/* Footer Bottom Text */}
        <p className="text-xs text-gray-500 text-center md:text-left">
          © {new Date().getFullYear()} NAE Coaching. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
