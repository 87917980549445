import React, { useState } from 'react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

const EmojiPickerComponent: React.FC<{ onEmojiSelect: (emoji: string) => void }> = ({ onEmojiSelect }) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    onEmojiSelect(emojiData.emoji); // Pass the selected emoji back to the parent
    setShowPicker(false); // Close the picker after selection
  };

  return (
    <div className="m-2">
      <div className='flex'>
      <button
        className="bg-green-500 text-white rounded-xl px-1  hover:bg-green-600 transition duration-300"
        onClick={() => setShowPicker(!showPicker)}
      >

        {showPicker ? 'Hide' : 'Edit'}
      </button>
      </div>
      <div>
      {showPicker && (
        <div className="px-2 flex justify-center z-10 bg-white border border-gray-300 rounded-lg shadow-lg p-2">
          <EmojiPicker onEmojiClick={handleEmojiClick} className='w-full' />
        </div>
      )}
      </div>
    </div>
  );
};

export default EmojiPickerComponent;
