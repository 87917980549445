// src/components/InstallButton.tsx
import React, { useEffect, useState } from 'react';
import { Download } from 'lucide-react';

const InstallButton2: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true); 
      // console.log('isInstallable handle before install: ', isInstallable);
      // Show the install button when the app is installable
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsInstallable(false);  // Hide the install button after the prompt is handled
      });
    }
  };

  if (!isInstallable) {
    // console.log('isInstallable : ', isInstallable);
    
    return null;  // Hide the button if the app is not installable
  }

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div 
        onClick={handleInstallClick}
        className="relative group cursor-pointer"
      >
        {/* Promotional Tag */}
        <div className="absolute -top-12 right-0 bg-blue-600 text-white text-xs font-bold px-3 py-1 rounded-t-lg shadow-lg">
          Install App & Save Time!
        </div>

        {/* Install Button */}
        <div className="flex items-center bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white px-4 py-2 rounded-lg shadow-xl transition-all duration-300 ease-in-out transform hover:scale-105">
          <Download className="mr-2" />
          <span className="font-semibold">Install NAE App</span>
        </div>

        {/* Subtle Tooltip */}
        <div className="absolute top-full left-0 mt-2 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          One-click install for quick access
        </div>
      </div>
    </div>
  );
};

export default InstallButton2;
