import React from 'react';

interface CommentCardProps {
  // img: string;           // Avatar image (URL)
  student_name: string;  // Student's name
  student_class: string; // Student's class
  body: string;          // Comment message
  rating: number;        // Rating (1-5)
  student_emoji: string; // Emoji representing the student
}

const CommentCard = ({
  student_name,
  student_class,
  body,
  rating,
  student_emoji,
}: CommentCardProps) => {
  return (
    <figure className="relative h-48 w-36 cursor-pointer overflow-hidden rounded-xl border p-4 border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05] dark:border-gray-50/[.3] dark:bg-gray-50/[.50] dark:hover:bg-gray-50/[.15] flex flex-col justify-between dark:border-white">
      
      <div className="flex flex-row gap-2 items-start">
        {/* Student Avatar */}
        <p className="text-3xl">{student_emoji}</p> {/* Display student emoji */}
        
        {/* Student Info (Name, Class, Emoji) */}
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium ">{student_name}</figcaption>
          <p className="text-xs font-medium ">{student_class}</p>
        </div>
      </div>
      
      {/* Comment Body */}
      <blockquote className="mt-2 text-sm">{body}</blockquote>
      
      {/* Rating */}
      <div className="mt-2 flex items-center">
        {[...Array(rating)].map((_, index) => (
          <svg
            key={index}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5 text-yellow-500"
          >
            <path
              fillRule="evenodd"
              d="M12 2l3.09 6.26L22 9.24l-5 4.87L18.18 21 12 17.77 5.82 21 7 14.11l-5-4.87 6.91-1.98L12 2z"
              clipRule="evenodd"
            />
          </svg>
        ))}
      </div>
    </figure>
  );
};

export default CommentCard;
