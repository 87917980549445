// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import Attendance from '../components/Attendance';
import Fees from '../components/Fees';
import Courses from '../components/profileCourses';
import Tests from '../components/ProfileTest';
import { useAuth } from '../context/AuthContext';
import EmojiPickerComponent from '../components/EmojiPicker';
import axios from 'axios';
import { GenreData } from '../components/interfaces';

// Define the interface for the student data
interface StudentData {
  id: number;
  name: string;
  student_class: string;
  emoji: string;
}

const genresData = {
  genres: [
    { icon: '✅', title: 'Attendance', info: 'Track your attendance records here.' },
    { icon: '📝', title: 'Tests', info: 'Review your test scores and upcoming tests.' },
    { icon: '🧾', title: 'Fees', info: 'Manage your fee payments and dues.' },
    { icon: '📅', title: 'Routine', info: 'View and organize your daily schedule.' },
    { icon: '🤔', title: 'Doubts', info: 'Get answers to your academic questions.' },
    { icon: '🎯', title: 'Goal', info: 'Set and track your personal goals.' },
    { icon: '🤹', title: 'Fun', info: 'Join fun activities and events.' },
    { icon: '🌍', title: 'Explore', info: 'Discover new topics and resources.' },
    { icon: '🕵️‍♀️', title: 'Mystery', info: 'Solve intriguing mysteries and puzzles.' },
  ],
};



const Profile: React.FC = () => {

  const { isAuthenticated, studentId, studentClass, studentName, studentEmoji, setStudentEmoji, enrolledCourses } = useAuth();  
  const [emoji, setEmoji] = useState<string>(studentEmoji || '😊'); // Default emoji
  const [studentData, setStudentData] = useState<StudentData | null>(null);
  const [update, setUpdate] = useState<Boolean>(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const getToken = (): string | null => {
    return localStorage.getItem('token');
  };
  // console.log(studentEmoji);
  
  const [selectedGenre, setSelectedGenre] = useState('');

  const handleGenreClick = (genreTitle: string) => {
    setSelectedGenre(selectedGenre === genreTitle ? '' : genreTitle);

  };

  const getStudentData = async () => {
    const token = getToken();
    try {
      const response = await axios.get(`${API_URL}/users/students/${studentId}/`, 
        {headers: {
          Authorization: `Token ${token}`, 
        },}
      );
      setStudentData(response.data)
      setStudentEmoji(response.data.emoji)
      // setEmoji(response.data.emoji);    
      // console.log(emoji);
      // setUpdate(!update);
        
      
    } catch (error) {
      console.error("Failed to update emoji", error);
    }
  }
  
  useEffect(() => {
    if (isAuthenticated) {
      getStudentData();
      }
    // console.log('emoji updated: ',emoji);
    
  }, [update]);
  

  const handleEmojiSelect = async (selectedEmoji: string) => {
    setEmoji(selectedEmoji);
    // console.log('Selected Emoji:', selectedEmoji);
    const token = getToken();

    if (!token) {
      throw new Error("No authentication token found.");
    }

    try {
      await axios.post(`${API_URL}/users/students/${studentId}/update_emoji/`, { emoji: selectedEmoji },
        {headers: {
          Authorization: `Token ${token}`, 
        },}
      );
      alert("Emoji updated successfully!");
      setEmoji(emoji);
      getStudentData();
    } catch (error) {
      console.error("Failed to update emoji", error);
    }
    // Here, you can also trigger an API call to update the emoji for the user
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white min-h-screen">
      {/* User Information Section */}
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mb-6 flex items-center">
        
        {/* <img src={user.profilePic} alt="Profile" className="w-24 h-24 rounded-full border-2 border-gray-300" /> */}
        <div className='relative'> 
        <p className="text-5xl rounded-full border-gray-300">{ studentEmoji || emoji}</p>
        <div className='absolute top-12 '>
          <EmojiPickerComponent onEmojiSelect={handleEmojiSelect} />
        </div>
        </div>
        <div className="ml-4">
          <h2 className="text-2xl font-bold">{studentName}</h2>
          <p className="text-gray-700">{studentClass}</p>
        </div>
        
      </div>
      <div className="w-full max-w-5xl flex flex-col items-center gap-4">
      <div className="flex flex-col items-center bg-white p-4">
        <h1 className="text-3xl font-bold mb-4">Performance!</h1>
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 md:gap-6 lg:gap-8">
          {genresData.genres.map((genre) => {
            const isSelected = selectedGenre === genre.title;
            return (
              <div
                key={genre.title}
                onClick={() => handleGenreClick(genre.title)}
                className={`rounded-xl shadow-lg lg:shadow-xl p-4 cursor-pointer hover:bg-gray-100 
                  ${isSelected ? 'w-full col-span-3 md:col-span-4 lg:col-span-6 bg-gray-100' : ''}`}
                style={{ height: isSelected ? 'auto' : '100px' }}
              >
                <div className="text-center text-2xl font-bold mb-2">{genre.icon}</div>
                <div className="text-center font-semibold">{genre.title}</div>
                {isSelected && (
                  <div className="mt-4 text-center p-2 bg-white rounded-lg">
                    <h2 className="text-lg font-semibold">{genre.title} Details</h2>
                    <p>{genre.info}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Profile;
