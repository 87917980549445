import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// Define the interface for the context
interface AuthContextProps {
  sendOtp: (phoneNumber: string, deviceId: string) => Promise<OtpResponse>;  // should match actual function type
  verifyOtp: (phoneNumber: string, otp: string) => Promise<OtpResponse>;  // should match actual function type
  login: (phoneNumber: string, password: string) => Promise<void>;
  signup: (userData: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    role: string;
  }) => Promise<void>;
  isAuthenticated: boolean;
  studentId: number | null;
  studentName: string | null;
  studentClass: string | null;
  studentEmoji: string | null;
  setStudentId: (studentId: number) => void;
  setStudentName: (studentName: string) => void;
  setStudentClass: (studentClass: string) => void;
  setStudentEmoji: (studentEmoji: string) => void;
  
  logout: () => Promise<void>;
  enrolledCourses: number[];
  authIntriger: boolean;
  setAuthIntriger: (authInriger: boolean) => void;
}

interface OtpResponse {
  status: string;
  message: string;
  [key: string]: any; // Add any additional properties as needed
}

interface OtpError {
  error: string;
  [key: string]: any; // Add any additional properties as needed
}



// Create the context
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Create a custom hook to use the context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Create the provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [studentId, setStudentId] = useState<number | null>(null);
  const [studentName, setStudentName] = useState<string | null>(null);
  const [studentClass, setStudentClass] = useState<string | null>(null);
  const [studentEmoji, setStudentEmoji] = useState<string | null>(null);
  const [enrolledCourses, setEnrolledCourses] = useState<number[]>([]);

  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [authIntriger, setAuthIntriger] = useState<boolean>(true);

  // Check token validity and initialize student data
  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/users/token-validity/`, {
            headers: { Authorization: `Token ${token}` },
          });

          if (response.status === 200) {
            setIsAuthenticated(true);

            const naeActiveStudent = localStorage.getItem('naeActiveStudent');
            if (naeActiveStudent) {
              const activeStudent = JSON.parse(naeActiveStudent);

              if (activeStudent.userId === response.data.userId) {
                setStudentId(activeStudent.studentId);
                setStudentClass(activeStudent.studentClass);
                setStudentName(activeStudent.studentName);
                setStudentEmoji(activeStudent.studentEmoji);

                if (activeStudent.studentId) {
                  try {
                    const coursesResponse = await axios.get(
                      `${API_URL}/courses/enrollments/student/${activeStudent.studentId}`,
                      { headers: { Authorization: `Token ${token}` } }
                    );
                    setEnrolledCourses(coursesResponse.data.map((course: any) => course.course));
                  } catch (error) {
                    console.error('Failed to fetch enrolled courses', error);
                  }
                }
              } else {
                navigate('/userPage');
              }
            }
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Token validity check error:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };
    // console.log('navigation happen: ', enrolledCourses);
    

    checkTokenValidity();
  }, [API_URL, studentId, authIntriger]);

  
  
  // Function to send OTP
  const sendOtp = async (phoneNumber: string, deviceId: string): Promise<OtpResponse> => {
    try {
      const response = await axios.post<OtpResponse>(`${API_URL}/users/send-otp/`, { 
        phone_number: phoneNumber, 
        device_id: deviceId 
      });
      console.log(response.data.message);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const statusCode = error.response.status;
        // Check for specific status codes
        if (statusCode === 400) {
          throw new Error("Please wait 5 minutes before requesting another OTP");
        } else if (statusCode === 405) {
          throw new Error("Not registered mobile number is required.");
        } else if (statusCode === 409) {
          throw new Error("Phone Number is already registered, please login.");
        } else if (statusCode === 429) {
          throw new Error("Wait for a few minutes and try again, too many attempts.");
        } else {
          const errMessage = error.response?.data?.message || 'Failed to send OTP';
          throw new Error(errMessage);
        }
      } else {
        throw new Error('Failed to send OTP');
      }
    }
  };
  
  // Function to verify OTP
  const verifyOtp = async (phoneNumber: string, otp: string): Promise<OtpResponse> => {
    try {
      const response = await axios.post<OtpResponse>(`${API_URL}/users/verify-otp/`, { phone_number: phoneNumber, otp: otp });
      console.log(response)
      return response.data;
    } catch (error: any) {
      const err = error.response?.data as OtpError;
      throw err;
    }
  };

  // Login function
  const login = async (phoneNumber: string, password: string) => {
    try {
      const response = await axios.post(`${API_URL}/users/login/`, { phoneNumber, password });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setIsAuthenticated(true);
        navigate('/usersPage');
      } else {
        throw new Error('Login failed: No token received.');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Signup function
  const signup = async (userData: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    role: string;
  }) => {
    try {
      const response = await axios.post(`${API_URL}/users/signUp/`, userData);

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setIsAuthenticated(true);
        navigate('/usersPage');
      } else {
        throw new Error('Signup failed: No token received.');
      }
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  };

  // Logout function
  const logout = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `${API_URL}/users/logout/`,
        {},
        { headers: { Authorization: `Token ${token}` } }
      );
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      setStudentId(null);
      setStudentClass(null);
      setStudentName(null);
      setStudentEmoji(null)
      localStorage.removeItem('naeActiveStudent');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error instanceof Error ? error.message : 'An unknown error occurred.');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        sendOtp,
        verifyOtp,
        login,
        signup,
        logout,
        isAuthenticated,
        studentId,
        studentName,
        studentClass,
        studentEmoji,
        setStudentEmoji,
        setStudentId,
        setStudentName,
        setStudentClass,
        enrolledCourses,
        authIntriger,
        setAuthIntriger,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
