import React from 'react';
import { useNavigate } from 'react-router-dom';

interface CollectiveCourse {
  id: number;
  title: string;
  description: string;
  course_img: string;
  collective_price: string;
  discount_percentage: string;
  courses: number[];
  tags: number[];
}

const CollectiveCourseCard = ({ course }: { course: CollectiveCourse }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/collectiveCoursesPage/${course.id}`);
  };

  const discountedPrice = (
    parseFloat(course.collective_price) -
    (parseFloat(course.collective_price) * parseFloat(course.discount_percentage)) / 100
  ).toFixed(2);


  return (
    <div className="min-w-60 max-w-80 h-full bg-white rounded-2xl shadow-md p-3 flex flex-col">
      <div className="flex items-center w-full">
        <img
          src={course.course_img || '/placeholder.png'} // Placeholder if image is null
          alt={course.title}
          className="w-full object-cover rounded-2xl mb-2"
        />
      </div>

      <div className="pt-2 flex flex-col flex-grow">
        <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
        <p className="text-lg text-gray-600 mb-2">{course.description}</p>
        <div className="flex items-center justify-between">
        <span className="text-lg font-bold text-gray-800">
          ₹{discountedPrice}
        </span>
        <span className="text-sm text-gray-500 line-through">
          ₹{course.collective_price}
        </span>
      </div>
      <span className="text-sm font-medium text-red-600">
          Save {course.discount_percentage}%!
        </span>

        <button
          onClick={handleViewDetails}
          className="mt-auto bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 transition duration-300"
        >
          Look into
        </button>
      </div>
    </div>
  );
};

export default CollectiveCourseCard;
