import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CourseCard } from '../components/CourseListHome';
import { CollectiveCourse } from '../components/interfaces';
import { useAuth } from '../context/AuthContext';
import CollectiveCourseCard from '../components/CollectiveCourseCard';



interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string | null;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

interface CoursesByClass {
  [key: string]: Course[];
}

const Courses: React.FC = () => {
  const { isAuthenticated, studentId, studentClass } = useAuth();
  const [coursesByClass, setCoursesByClass] = useState<CoursesByClass>({});
  const [isLoading, setIsLoading] = useState(true);
  const [collectiveCourses, setCollectiveCourses] = useState<CollectiveCourse[]>([]);
  const [yourCourses, setYourCourses] = useState<Course[]>([]);
  const [bestCourses, setBestCourses] = useState<Course[]>([]);
  const [yourCollectiveCourses, setYourCollectiveCourses] = useState<CollectiveCourse[]>([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCoursesByClass = async () => {
      const cachedCoursesByClass= localStorage.getItem(`CoursesByClass`);
      const CoursesByClassExpiry = localStorage.getItem(`CoursesByClassExpiry`);
      const now = new Date();
      
      
      if (cachedCoursesByClass && CoursesByClassExpiry && now < new Date(CoursesByClassExpiry)) {
        // If cached data exists and is not expired, use it
        setCoursesByClass(JSON.parse(cachedCoursesByClass));
        setIsLoading(false);
      } else {
        try {
          const response = await axios.get(`${API_URL}/courses/classwise/`);
          setCoursesByClass(response.data);
          setIsLoading(false);
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem('CoursesByClass', JSON.stringify(response.data));
          localStorage.setItem('CoursesByClassExpiry', new Date(now.getTime() + 1 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching class-wise courses:', error);
          setIsLoading(false);
        }
      }
    };

    const fetchYourCourses = async () => {
      const cachedYourCourses = localStorage.getItem(`your${studentId}${studentClass}Courses`);
      const yourCoursesExpiry = localStorage.getItem(`your${studentId}${studentClass}CoursesExpiry`);
      const now = new Date();
  
      if (cachedYourCourses && yourCoursesExpiry && now < new Date(yourCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setYourCourses(JSON.parse(cachedYourCourses));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/courses/by-class/${studentClass}`);
          console.log(response);
          
          setYourCourses(response.data.results);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`your${studentId}${studentClass}Courses`, JSON.stringify(response.data));
          localStorage.setItem(`your${studentId}${studentClass}CoursesExpiry`, new Date(now.getTime() +  1 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };

    const fetchBestCourses = async () => {
      const cachedBestCourses = localStorage.getItem(`Best${studentId}${studentClass}Courses`);
      const BestCoursesExpiry = localStorage.getItem(`Best${studentId}${studentClass}CoursesExpiry`);
      const now = new Date();
  
      if (cachedBestCourses && BestCoursesExpiry && now < new Date(BestCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setBestCourses(JSON.parse(cachedBestCourses));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/courses/best`);
          // console.log(response);
          
          setBestCourses(response.data.results);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`Best${studentId}${studentClass}Courses`, JSON.stringify(response.data));
          localStorage.setItem(`Best${studentId}${studentClass}CoursesExpiry`, new Date(now.getTime() + 1 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
    };

    
    const fetchYourCollectiveCourses = async () => {
      const cachedYourCollectiveCourses = localStorage.getItem(`your${studentId}${studentClass}CollectiveCourses`);
      const yourCollectiveCoursesExpiry = localStorage.getItem(`your${studentId}${studentClass}CollectiveCoursesExpiry`);
      const now = new Date();
  
      if (cachedYourCollectiveCourses && yourCollectiveCoursesExpiry && now < new Date(yourCollectiveCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        setYourCollectiveCourses(JSON.parse(cachedYourCollectiveCourses));

      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/collective-courses/by-class/${studentClass}`);
          console.log(response);

          setYourCollectiveCourses(response.data);
          
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem(`your${studentId}${studentClass}CollectiveCourses`, JSON.stringify(response.data));
          localStorage.setItem(`your${studentId}${studentClass}CollectiveCoursesExpiry`, new Date(now.getTime() + 1 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching Collectivecourses:', error);
        }
      }
    };
    const fetchCollectiveCourses = async () => {
      const cachedCollectiveCourses = localStorage.getItem('collectiveCourses');
      const collectiveCoursesExpiry = localStorage.getItem('collectiveCoursesExpiry');
      const now = new Date();
  
      if (cachedCollectiveCourses && collectiveCoursesExpiry && now < new Date(collectiveCoursesExpiry)) {
        // If cached data exists and is not expired, use it
        // console.log(cachedCollectiveCourses);
        try{
          setCollectiveCourses(JSON.parse(cachedCollectiveCourses));
        } catch(error) {
          console.error('Error fetching collective courses from cache:', error);
        }
        
      } else {
        // Otherwise, fetch from server and cache the result
        try {
          const response = await axios.get(`${API_URL}/courses/collective-courses/`);
          setCollectiveCourses(response.data);
          // console.log(response);
          
  
          // Cache the data and set an expiry time (e.g., 1 day)
          localStorage.setItem('collectiveCourses', JSON.stringify(response.data));
          localStorage.setItem('collectiveCoursesExpiry', new Date(now.getTime() + 1 * 60 * 1000).toISOString());
        } catch (error) {
          console.error('Error fetching collective courses:', error);
        }
      }
    };
  

    fetchCoursesByClass();
    fetchCollectiveCourses();
    
    if(studentId && studentClass){
      fetchYourCourses();
      fetchYourCollectiveCourses();
    }
  }, [API_URL]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      {bestCourses.length != 0 ? (
      <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Our Best Courses</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {bestCourses.map(course => (
                <div className='w-full flex justify-center items-center'>
                <CourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
        </div>) : (<></>)}
      {studentId ? (
        <div>
        {yourCourses.length != 0 ? (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl text-center font-bold text-gray-900">Courses for YOU</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {yourCourses.map(course => (
                <div className='w-full flex justify-center items-center'>
                <CourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
        </div>
        ) : (<></>)}

        {yourCollectiveCourses.length != 0 ? (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl md:text-3xl mb-3 font-bold text-gray-900">Collective Courses for YOU</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {yourCollectiveCourses.map(course => (
                <div className='w-full flex justify-center items-center'>
                  <CollectiveCourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
        </div>
        ) : (<></>)}
        </div>
      ) : (<></>)}


      <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">Class-wise Courses</h1>
      {Object.entries(coursesByClass).map(([className, courses]) => {
        // Capitalize and format class name
        const formattedClassName = className
          .replace(/_/g, ' ')
          .replace(/\b\w/g, char => char.toUpperCase());

        if (courses.length === 0) return null;

        return (
          <div key={className} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">{formattedClassName}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {courses.map(course => (
                <div className='w-full flex justify-center items-center'>
                <CourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
          </div>
        );
      })}

      <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">Collective Courses</h1>
      
  
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {collectiveCourses.map(course => (
                <div className='w-full flex justify-center items-center'>
                <CollectiveCourseCard key={course.id} course={course} />
                </div>
              ))}
            </div>
    </div>
  );
};

export default Courses;
