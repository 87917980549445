import React from 'react';
import CollectiveCourseCard from './CollectiveCourseCard';
import { Link } from 'react-router-dom';

interface CollectiveCourse {
  id: number;
  title: string;
  description: string;
  course_img: string;
  collective_price: string;
  discount_percentage: string;
  courses: number[];
  tags: number[];
}

const CollectiveCourseList = ({ courses }: { courses: CollectiveCourse[] }) => {
  if (!Array.isArray(courses) || courses.length === 0) {
    return <p>No collective courses available.</p>;
  }

  return (
    <div className="container mx-auto px-2 py-4">
      <div className="flex overflow-x-auto space-x-6 no-scrollbar py-3">
        {courses.map((course) => (
          <CollectiveCourseCard key={course.id} course={course} />
        ))}
      </div>
      <div className="mt-8">
      <Link to={'/courses'} >
        <button className="bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
          Show All
        </button>
        </Link>
      </div>
    </div>
  );
};

export default CollectiveCourseList;
